<template>
  <div class="tellphone">
    <div class="portrait fl-x-c">
      <div class="icon1 fl-x-c">
        <div class="icon2 fl-x-c">
          <div class="icon3 fl-x-c">
            <div class="icon4 fl-x-c">
              <img src="@/assets/img/头像@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="number fl-x-c sz-42 f-color1 fw-bold">152 2752 8672</div>
    <div v-if="!drawer" class="text fl-x-c sz-30 fw-500 f-color9">拨号中...</div>
    <div v-else class="text fl-x-c sz-30 fw-500 f-color9">
      剩余{{ number }}秒
    </div>
    <!-- 底部抽屉 -->
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
      direction="btt"
      size="48%"
    >
      <div class="adress fl-x-c">
        <img src="@/assets/img/地址@2x.png" alt="" />
        <div class="sz-28 f-color1">武昌区中北路锦绣中北二期三栋</div>
      </div>
      <div class="fl-x-c">
        <div class="sz-24 fw-400 f-color2">设备编号：</div>
        <div class="sz-24 fw-400 f-color1">FC0271436</div>
      </div>
      <div class="hangup fl-x-c">
        <img @click="hangup" src="@/assets/img/电  话 (1)@2x.png" alt="" />
      </div>
    </el-drawer>
    <!-- 提示弹框 -->
    <el-dialog
      :visible.sync="pupShow"
      width="80%"
      :show-close="false"
      center
      top="25vh"
    >
      <span class="sz-28 fw-400 f-color6 dialog-center"
        >是否将当前位置以短信发送给对方</span
      >
      <span slot="footer" class="footer fl-x-b dev-l-t">
        <div
          class="dialog-btn1 f-color2 sz-24 fw-500 fl-x-c f-color0"
          @click="close"
        >
          取消
        </div>
        <div class="dialog-btn2 sz-24 fw-500 fl-x-c f-color0" @click="edit">
          确 定
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      number: 180,
      pupShow: false,
      timeOut: "",
      isphone: true, //是否拨打是手机号
    };
  },
  mounted() {
    this._setTime();
  },
  methods: {
    _setTime() {
      setTimeout(() => {
        this.drawer = true;
        this._setInter();
      }, 3000);
    },
    //挂机
    hangup() {
      this.drawer = false;
      clearInterval(this.timeOut);
      if (this.isphone) {
        this.pupShow = true;
      } else {
        this.$router.push({ path: "/" });
      }
    },
    _setInter() {
      this.timeOut = setInterval(() => {
        if (this.number > 0) {
          this.number--;
        } else {
          this.hangup();
          clearInterval(this.timeOut);
        }
      }, 1000);
    },
    // 取消
    close() {
      this.pupShow = false;
      this.$router.push({ path: "/" });
    },
    //确定
    edit() {
      this.pupShow = false;
      this.$router.push({ path: "/" });
    },
  },
  destroyed() {
    clearInterval(this.timeOut);
  },
};
</script>

<style lang="scss" scoped>
.tellphone {
  width: 100%;
  height: 100vh;
  background: url("../../assets/img/背景图1.png") no-repeat;
  background-size: 100% 100%;
}
.portrait {
  padding: 46px 0 30px;
  .icon1 {
    width: 246px;
    height: 246px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    z-index: 10;
    .icon2 {
      width: 205px;
      height: 205px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      z-index: 20;
      .icon3 {
        width: 158px;
        height: 158px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        z-index: 30;
        .icon4 {
          width: 116px;
          height: 116px;
          z-index: 50;
        }
        img {
          width: 116px;
          height: 116px;
        }
      }
    }
  }
}
.text {
  margin-top: 44px;
}
/deep/ .el-drawer {
  border-radius: 36px 36px 0 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.9) !important;
  .adress {
    padding: 30px 0 14px 0;
    img {
      width: 30px;
      height: 36px;
      margin-right: 14px;
    }
  }
}
.hangup {
  margin-top: 185px;
  img {
    width: 97px;
    height: 97px;
  }
}
.dialog-center {
  line-height: 48px;
  display: inline-block;
  padding: 45px 33px 32px;
  width: 100%;
}
.footer {
  padding: 30px 0;
  margin: 0 33px;
  .dialog-btn1,
  .dialog-btn2 {
    width: 170px;
    height: 56px;
    border-radius: 10px;
  }
  .dialog-btn2 {
    background: #24dadb;
  }
  .dialog-btn1 {
    border: 1px solid #24dadb;
    background-color: #fff;
  }
}
</style>
